<template>
  <div class="bg-[var(--c-bg)] relative text-center p-2 pt-15 pb-10">
    <p class="font-size-[12px] op50">
      <a class="underline" href="https://creativecommons.org/licenses/by-nc-sa/4.0/">
        CC BY-NC-SA 4.0
      </a>
      2021-PRESENT © <a href="https://github.com/shellingfordly" target="_blank">shellingfordly</a>
    </p>
    <div class="font-size-[12px] op50 space-x-3">
      <span id="busuanzi_container_site_uv">
        <span class="i-carbon-user mr-1" />
        <span id="busuanzi_value_site_uv">0</span>
      </span>
      <span id="busuanzi_container_site_pv">
        <span class="i-carbon-view mr-1" />
        <span id="busuanzi_value_site_pv">0</span>
      </span>
    </div>
  </div>
</template>
